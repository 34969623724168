import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../images/logo.svg';

const ElevationScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};
const useStyles = makeStyles((theme) => ({
  contact: {
    color: theme.palette.common.blue,
  },
  navContainer: {
    marginLeft: 'auto',
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      height: '2em',
      width: 'auto',
    },
  },
  drawerIcon: {
    color: theme.palette.common.white,
    height: '50px',
    width: '50px',
  },
  drawerIconContainer: {
    marginLeft: 'auto',
  },
  drawer: {
    backgroundColor: theme.palette.primary.main,
    width: '25vw',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    },
  },
  drawerItem: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: '1.5rem',
    },
  },
  drawerItemContact: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: '1.5rem',
    },
  },
  appbar: {
    zIndex: theme.zIndex.modal + 1,
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: '2rem',
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  // const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleChange = (e, newValue) => {
    props.setValue(newValue);
  };

  useEffect(() => {
    switch (window.location.pathname) {
      case '/':
        if (props.value !== 0) {
          props.setValue(0);
        }
        break;
      case '/services':
        if (props.value !== 1) {
          props.setValue(1);
        }
        break;
      case '/about':
        if (props.value !== 2) {
          props.setValue(2);
        }
        break;
      case '/contact':
        if (props.value !== 3) {
          props.setValue(3);
        }
        break;
      default:
        if (props.value !== null) {
          props.setValue(null);
        }
        break;
    }
  }, [props]);

  const tabs = (
    <Tabs
      value={props.value}
      indicatorColor="secondary"
      onChange={handleChange}
      className={classes.navContainer}
    >
      <Tab disableRipple component={Link} to="/" label="Projects" />
      <Tab disableRipple component={Link} to="/services" label="Services" />
      <Tab disableRipple component={Link} to="/about" label="About" />
      <Tab
        disableRipple
        component={Link}
        className={classes.contact}
        to="/contact"
        label="Contact"
      />
    </Tabs>
  );

  const drawer = (
    <React.Fragment>
      <IconButton
        onClick={() => {
          setOpenDrawer(!openDrawer);
        }}
        disableRipple
        className={classes.drawerIconContainer}
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        // disableBackdropTransition={!iOS}
        // disableDiscovery={iOS}
        open={openDrawer}
        onOpen={() => {
          setOpenDrawer(true);
        }}
        onClose={() => {
          setOpenDrawer(false);
        }}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.toolbarMargin} />
        <List>
          <ListItem
            onClick={() => {
              setOpenDrawer(false);
              props.setValue(0);
            }}
            button
            disableRipple
            component={Link}
            to="/"
            selected={props.value === 0}
          >
            <ListItemText className={classes.drawerItem}>Projects</ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setOpenDrawer(false);
              props.setValue(1);
            }}
            button
            disableRipple
            component={Link}
            to="/services"
            selected={props.value === 1}
          >
            <ListItemText className={classes.drawerItem}>Services</ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setOpenDrawer(false);
              props.setValue(2);
            }}
            button
            disableRipple
            component={Link}
            to="/about"
            selected={props.value === 2}
          >
            <ListItemText className={classes.drawerItem}>About</ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setOpenDrawer(false);
              props.setValue(3);
            }}
            button
            disableRipple
            component={Link}
            to="/contact"
            selected={props.value === 3}
          >
            <ListItemText className={classes.drawerItemContact}>
              Contact
            </ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <ElevationScroll {...props}>
        <AppBar position="fixed" className={classes.appbar}>
          <Toolbar disableGutters>
            <Button
              onClick={() => props.setValue(0)}
              component={Link}
              to="/"
              disableRipple
            >
              <img className={classes.logo} alt="logo" src={logo} />
            </Button>
            {matches ? drawer : tabs}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );
};
export default Header;
