import React from 'react';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MailIcon from '@material-ui/icons/Mail';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    height: '10rem',
    position: 'absolute',
    bottom: 0,
    paddingBlockEnd: '1rem',
    [theme.breakpoints.down('md')]: {
      height: '6rem',
    },
    [theme.breakpoints.down('xs')]: {
      bottom: '-1rem',
    },
  },
  gridItem: {
    margin: '3em',
    marginTop: '2em',
    marginBottom: '2em',
  },
  link: {
    color: theme.palette.common.white,
    textDecoration: 'none',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: '0.75em',
  },
  icon: {
    height: '2em',
    width: '2em',
    textDecoration: 'none',
    verticalAlign: 'bottom',
  },
  iconContainer: {
    marginLeft: '10em',
    marginRight: '10em',
    [theme.breakpoints.down('md')]: {
      margin: '1.25em',
      marginBlock: '1em',
    },
  },
  copyright: {
    color: theme.palette.common.white,
    marginLeft: '1em',
    marginBlock: 0,
  },
  linkedin: {
    color: '#0077b5',
  },
  github: {
    color: 'white',
  },
  mail: {
    color: '#e52d27',
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Hidden mdDown>
        <Grid container justify="center">
          <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={1}>
              <Grid
                item
                className={classes.link}
                component={Link}
                to="/"
                onClick={() => props.setValue(0)}
              >
                Projects
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={1}>
              <Grid
                item
                className={classes.link}
                component={Link}
                to="/services"
                onClick={() => props.setValue(1)}
              >
                Services
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={1}>
              <Grid
                item
                className={classes.link}
                component={Link}
                to="/about"
                onClick={() => props.setValue(2)}
              >
                About Me
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={1}>
              <Grid
                item
                className={classes.link}
                component={Link}
                to="/contact"
                onClick={() => props.setValue(3)}
              >
                Contact Me
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Grid container justify="center" spacing={0}>
        <Grid item className={classes.iconContainer}>
          <IconButton
            component={'a'}
            href="https://www.linkedin.com/in/nabhas-alakkat-a432b41b9/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <LinkedInIcon className={[classes.icon, classes.linkedin]} />
          </IconButton>
        </Grid>
        <Grid item className={classes.iconContainer}>
          <IconButton
            component={'a'}
            href="https://github.com/nabualakkat"
            rel="noopener noreferrer"
            target="_blank"
          >
            <GitHubIcon className={[classes.icon, classes.github]} />
          </IconButton>
        </Grid>
        <Grid item className={classes.iconContainer}>
          <IconButton
            component={'a'}
            href="mailto:alakkat.n@gmail.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            <MailIcon className={[classes.icon, classes.mail]} />
          </IconButton>
        </Grid>
      </Grid>
      <p className={classes.copyright}>&copy; Nabhas Alakkat 2021</p>
    </footer>
  );
};

export default Footer;
