import { createMuiTheme } from '@material-ui/core';

const portGrey = '#393e46';
const portDarkGrey = '#222831';
const portBlue = '#00adb5';

export default createMuiTheme({
  palette: {
    common: {
      grey: `${portGrey}`,
      darkGrey: `${portDarkGrey}`,
      blue: `${portBlue}`,
      white: '#EEEEEE',
    },
    primary: {
      main: `${portDarkGrey}`,
    },
    secondary: {
      main: `${portBlue}`,
    },
  },
  typography: {
    fontFamily: 'Lato',
    textTransform: 'none',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSize: '1rem',
    header: {
      textAlign: 'center',
      fontFamily: 'Lato',
      fontSize: '3.5rem',
      fontWeight: 300,
      marginBottom: '1rem',
      letterSpacing: '.1rem',
    },
    headerTwo: {
      textAlign: 'left',
      fontFamily: 'Lato',
      fontSize: '2.5rem',
      fontWeight: 400,
      marginBottom: '1rem',
      letterSpacing: '.1rem',
    },
    headerThree: {
      textAlign: 'left',
      fontFamily: 'Lato',
      fontSize: '1.75rem',
      fontWeight: 700,
      marginBottom: '.9rem',
    },
    subheader: {
      textAlign: 'center',
      fontFamily: 'Lato',
      fontSize: '1.5rem',
      fontWeight: '700',
      letterSpacing: '.45rem',
    },
    body: {
      textAlign: 'center',
      fontFamily: 'Lato',
      fontSize: '1.25rem',
      marginBottom: '1rem',
      lineHeight: '2.5rem',
      fontWeight: 400,
    },
    cardText: {
      textAlign: 'center',
      fontFamily: 'Lato',
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '1.2rem',
    },
    listItem: {
      textAlign: 'left',
      fontFamily: 'Lato',
      fontSize: '1.25rem',
      margin: '0 3rem',
      marginBottom: '1rem',
      lineHeight: '2.5rem',
      fontWeight: 400,
    },
  },
});
