import React, {useState} from 'react'
import {ThemeProvider, makeStyles} from '@material-ui/styles'
import Header from './Header'
import Footer from './Footer'
import theme from '../Theme'
import './app.css'

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "100vh",
    position: "relative"
  }
}))
const Layout = ({children}) => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  return (
  <ThemeProvider theme={theme}>
    <div className={classes.container}>
      <Header value={value} setValue={setValue}/>
        {children}
      <Footer value={value} setValue={setValue}/>
    </div>
  </ThemeProvider>
)}

export default Layout
